import {
  FETCH_ALL_PARTNERS_REQUEST,
  FETCH_ALL_PARTNERS_SUCCESS,
  FETCH_ALL_PARTNERS_FAILURE,
} from "../actions/partners-actions/types";

const intialState = {
isLoading:null,
error:null,
 data:null
};

const partnerReducer = (state = intialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PARTNERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ALL_PARTNERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        error: null,
        isLoading: false,
      };

    case FETCH_ALL_PARTNERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default partnerReducer;
