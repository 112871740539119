import { combineReducers } from "redux";
import homeReducer from "./home-reducer";
import partnerReducer from "./partner-reducer";
import testimoniesReducer from "./testimonies-reducer";
const rootReducer = combineReducers({
  homeReducer,
  partnerReducer,
  testimoniesReducer,
});

export default rootReducer;
