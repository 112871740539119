import React from 'react'
import Header from '../../shared/header'
import { Grid, Link, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import nvidia from "../../assets/logos/nvidia.svg";
import video from "../../assets/videos/DGX_Reveal V5.mp4";
import "./style.css"
export default function UnderConstruction() {
  return (
    <div className="under-construction-container">
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ mt: "auto" }}
      >
        <Grid
          xl={6}
          md={6}
          xs={12}
          item
          justifyContent={"start"}
          alignItems={"center"}
        >
          <ReactPlayer
            playIcon={<img src={nvidia} />}
            url={video}
            width="100%"
            height="100%"
            controls
          />
        </Grid>
      </Grid>
    </div>
  );
}
