import {
  FETCH_HOME_REQUEST,
  FETCH_HOME_SUCCESS,
  FETCH_HOME_FAILURE,
} from "../actions/home-actions/types";

const intialState = {
isLoading:null,
error:null,
 data:null
};

const bannersReducer = (state = intialState, action) => {
  switch (action.type) {
    case FETCH_HOME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_HOME_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        error: null,
        isLoading: false,
      };

    case FETCH_HOME_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default bannersReducer;
