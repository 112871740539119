import { Route, Routes } from 'react-router';
import './App.css';
import Home from './pages/home';
import { BrowserRouter } from 'react-router-dom';
import DesignSystem from './pages/design-system/designSystem';
import UnderConstruction from './pages/under-construction/under-construction';

function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
        {process.env.REACT_APP_UNDER_CONSTRUCTION === true ? (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<UnderConstruction />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
