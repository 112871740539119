import { createTheme, responsiveFontSizes } from "@mui/material";

import "@fontsource/poppins/600.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/100.css";


const underLinedButton= {
               content: '""',
               position: "absolute",
               width: "100%",
               height: 2,
               bottom: 0,
               left: 0,
               backgroundColor: "#D31A2B",
               animation: "$underline 0.3s forwards",
               
             }
 let theme = createTheme({
   components: {
     MuiButton: {
       styleOverrides: {
         root: {
           borderRadius: 8, // customize border-radius
           padding: "10px 20px", // customize padding
         },
       },
       variants: [
         {
           props: { variant: "text", color: "primary" },
           style: {
             fontFamily: "Poppins",
             fontWeight: 600,
             "&:hover::before": underLinedButton,
           },
         },
         {
           props: { variant: "text", color: "white" },
           style: {
             fontFamily: "Poppins",
             fontWeight: 600,
             textTransform: "none",
             underLinedButton,
             "&:hover::before": underLinedButton,
             "&:hover": {
               color: "#D31A2B",
               backgroundColor: "transparent", // customize hover color
             },
           },
         },
         {
           props: { variant: "contained", color: "primary" },
           style: {
             border: 0,
             borderRadius: 0,
             fontFamily: "Poppins",
             textTransform: "none",
             fontWeight: 600,
             "&:hover": {
               color: "#002060",
               backgroundColor: "#D31A2B", // customize hover color
             },
           },
         },
         {
           props: { variant: "contained", color: "white" },
           style: {
             border: 0,
             borderRadius: 0,
             fontFamily: "Poppins",
             textTransform: "none",
             fontWeight: 600,
             "&:hover": {
               color: "#fff",
               border:"1px solid #fff",
               backgroundColor: "transparent", // customize hover color
             },
           },
         },
         {
           props: { variant: "contained", color: "secondary" },
           style: {
             border: 0,
             borderRadius: 0,
             fontFamily: "Poppins",
             textTransform: "none",
             fontWeight: 600,
             "&:hover": {
               color: "#002060",
               backgroundColor: "#D31A2B", // customize hover color
             },
           },
         },
         {
           props: { variant: "outlined", color: "secondary" },
           style: {
             borderRadius: 0,
             fontFamily: "Poppins",
             textTransform: "none",
             fontWeight: 600,
             "&:hover": {
               color: "white",
               backgroundColor: "#D31A2B", // customize hover color
             },
           },
         },
         // Add more variants as needed (outlined, text, etc.)
       ],
     },
   },
   palette: {
     primary: {
       main: "#002060",
     },
     white: {
       main: "#fff",
     },
     secondary: {
       main: "#D31A2B",
     },
   },
   typography: {
     a: {
       fontWeight: 700,
       fontFamily: "Poppins",
     },
     h1: {
       fontWeight: 700,
       fontFamily: "Poppins",
     },
     h2: {
       fontWeight: 600,
       fontFamily: "Poppins",
     },
     h3: {
       fontWeight: 800,
       fontFamily: "Poppins",
     },
     h4: {
       fontWeight: 800,
       fontFamily: "Poppins",
     },
     h5: {
       fontWeight: 500,
       fontFamily: "Poppins",
     },
     h6: {
       fontWeight: 300,
       fontFamily: "Poppins",
     },
     link: {
       fontWeight: 600,
       fontFamily: "Poppins",
     },
     p: {
       fontWeight: 400,
       fontFamily: "Poppins",
       color: "black",
       textAlign: "left !important",
     },
   },
 });
 theme = responsiveFontSizes(theme);
 export {theme}

